import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import axios from 'axios'
Vue.use(Router)
let router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            props: true,
            name: 'Home',
            component: () =>
                import ('./components/Home/home'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: "/loginForm",
            name: "login",
            component: () =>
                import ("./components/Login/loginForm"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/signUpForm",
            name: "signUp",
            component: () =>
                import ("./components/Login/signUp"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: '/ecoShop',
            props: true,
            name: 'ecoShop',
            component: () =>
                import ('./components/EcoShop/ecoShop'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/ecoShopList',
            props: true,
            name: 'ecoShopList',
            component: () =>
                import ('./components/EcoShop/ecoShopList'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/about',
            props: true,
            name: 'about',
            component: () =>
                import ('./components/About/about'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/blogList',
            props: true,
            name: 'blogList',
            component: () =>
                import ('./components/Blog/blogList'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/blogView',
            props: true,
            name: 'blogView',
            component: () =>
                import ('./components/Blog/blogView'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/productDetails',
            props: true,
            name: 'productDetails',
            component: () =>
                import ('./components/EcoShop/productDetails'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/cart',
            props: true,
            name: 'cart',
            component: () =>
                import ('./components/Cart/cart'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/address',
            props: true,
            name: 'address',
            component: () =>
                import ('./components/Cart/address'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/wishlist',
            props: true,
            name: 'wishlist',
            component: () =>
                import ('./components/Wishlist/wishlist'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/feedback',
            props: true,
            name: 'feedback',
            component: () =>
                import ('./components/Common/feedback'),
            meta: {
                requiresAuth: false,
                isClient: true,

            },
        },
        {
            path: '/Profile',
            meta: {
                label: 'Profile',
                requiresAuth: true,
                isClient: true,
                isSeller: true,

            },
            component: () =>
                import ('./components/Profile/profile'),
            children: [{
                    path: '',
                    name: 'Profile',
                    meta: {
                        label: 'Profile',
                        requiresAuth: true,
                        isClient: true,
                        isSeller: true,

                    },
                    component: () =>
                        import ('./components/Profile/profileDetails'),
                },
                {
                    path: 'Orders',
                    name: 'Profle Orders',
                    meta: {
                        requiresAuth: true,
                        isClient: true,
                        isSeller: false,

                    },
                    component: () =>
                        import ('./components/Profile/orders'),
                },
                {
                    path: 'OrderDetails',
                    name: 'Profle Order Details',
                    meta: {
                        requiresAuth: true,
                        isClient: true,
                        isSeller: false,

                    },
                    component: () =>
                        import ('./components/Profile/orderDetails'),
                },
                {
                    path: 'Addresses',
                    name: 'Addresses',
                    meta: {
                        requiresAuth: true,
                        isClient: true,
                        isSeller: false,

                    },
                    component: () =>
                        import ('./components/Profile/addresses'),
                },
                {
                    path: 'PrivacyPolicy',
                    name: 'PrivacyPolicy',
                    meta: {
                        requiresAuth: true,
                        isClient: true,
                        isSeller: false,

                    },
                    component: () =>
                        import ('./components/Profile/privacyPolicy'),
                },
                {
                    path: 'CancellationPolicy',
                    name: 'CancellationPolicy',
                    meta: {
                        requiresAuth: true,
                        isClient: true,
                        isSeller: false,

                    },
                    component: () =>
                        import ('./components/Profile/cancellationPolicy'),
                },
                {
                    path: 'TermsAndConditions',
                    name: 'TermsAndConditions',
                    meta: {
                        requiresAuth: true,
                        isClient: true,
                        isSeller: false,

                    },
                    component: () =>
                        import ('./components/Profile/termsAndConditions'),
                },
            ]
        },
        {
            path: "/PaywaitScreen",
            name: "PaywaitScreen",
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ("./components/Payment/PaywaitScreen"),
        },
        {
            path: "/Wait",
            name: "Wait",
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ("./components/Payment/Wait"),
        },
        {
            path: "/Successccavenue",
            name: "Successccavenue",
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ("./components/Payment/Successccavenue"),
        },
        {
            path: "/Failureccavenue",
            name: "Failureccavenue",
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ("./components/Payment/Failureccavenue"),
        },
        {
            path: "/TermsAndConditions",
            name: "TermsAndConditions",
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ("./components/Common/termsAndConditions"),
        },
        {
            path: "/PrivacyPolicy",
            name: "PrivacyPolicy",
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ("./components/Common/privacyPolicy"),
        },
        {
            path: "/CancellationPolicy",
            name: "CancellationPolicy",
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ("./components/Common/cancellationPolicy"),
        },
        {
            path: '/forgotPassword',
            name: 'forgotPassword',
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ('./components/ForgotPassword/forgotPassword'),
        },
        {
            path: '/resetPassword',
            name: 'resetPassword',
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ('./components/ForgotPassword/resetPassword'),
        },
        {
            path: "/ContactUs",
            name: "ContactUs",
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ("./components/Common/contactUs"),
        },
        {
            path: '/ServerError',
            name: 'ServerError',
            props: true,
            component: () =>
                import ('./components/Common/500'),
            meta: {
                requiresAuth: false,
                isClient: true,

            }
        },
        {
            path: '*',
            name: '404PageNotFound',
            props: true,
            component: () =>
                import ('./components/Common/404'),
            meta: {
                requiresAuth: false,
                isClient: true,

            }
        },

    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
})
router.beforeEach((to, from, next) => {
    if (to.name !== "login" && to.name !== "signUp" && to.name !== 'forgotPassword' && to.name !== "resetPassword") {
        localStorage.setItem("routeKey", to.fullPath)
            // var key = localStorage.getItem("routeKey", to.fullPath)
            // console.log("routeKey", key)
    }
    // if (to.name == "login" && key == '/cart' && store.state.isLoggedIn == true) {
    //     console.log("routeKey", key)
    //     next({ name: "address" });
    //     return;
    // }
    // if ((to.fullPath !== "/loginForm" && to.fullPath !== "/signUp" && to.name !== 'forgotPassword' && to.name !== "resetPassword")) {
    //     localStorage.setItem("routeKey", to.fullPath)
    // }
    if (
        to.matched.some((route) => route.name == "login") &&
        store.state.isLoggedIn
    ) {
        next({ name: "home" });
        return;
    }
    if (store.state.isLoggedIn == true || to.matched.some(route => route.meta.requiresAuth == true)) {
        axios({
                method: 'POST',
                url: '/validate/token',
                headers: {
                    token: localStorage.getItem('token')
                }
            }).then(response => {
                if (!response.data.status) {
                    store.commit("sessionOut", true)
                    return
                }
            })
            .catch(err => {
                var msg = err;
                console.log(msg)
            });
    }
    if ((to.name == 'login') && store.state.isLoggedIn == true) {
        next({ name: 'home' })
        return
    }
    if ((to.name == 'signUp') && store.state.isLoggedIn == true) {
        next({ name: 'home' })
        return
    }
    next()
        // if (to.name == 'login' && store.state.isLoggedIn == true && (store.state.userType == 'User') && (store.state.userType == 'Institution')) {
        //     next({ name: 'Home' })
        //     return
        // }
        // if (to.matched.some(route => route.meta.isClient == false) && store.state.isLoggedIn == true && (store.state.userType == 'User') && (store.state.userType == 'Institution')) {
        //     next({ name: 'Home' })
        //     return
        // }
        // next()
})

export default router