<template>
  <v-app>
    <AppHeader v-if="$route.name == 'Home'" />
    <AppHeader2
      v-if="
        $route.name == 'ecoShop'
      "
    />
    <AppHeader0 v-if="$route.name != 'Home' &&  $route.name != 'ecoShop'" />
    <!-- <v-divider></v-divider> -->
    <router-view :key="$route.fullPath" v-bind:storage="sideNav" />
    <AppFooter/>
  </v-app>
</template>

<script>
import axios from "axios";
import AppHeader from "./components/Common/AppHeader";
import AppHeader0 from "./components/Common/AppHeader0";
import AppHeader2 from "./components/Common/AppHeader2";
import AppFooter from "./components/Common/AppFooter";
import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";
export default {
  name: 'App',

  components: {
    AppHeader,
    AppHeader0,
    AppHeader2,
    AppFooter
  },
  computed: {
    appType() {
      // console.log(this.$store.state.userRole);
      return this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.userId
    }
  },
  data: () => ({
    //
    sideNav: true,

  }),
  watch: {
    userId() {
      if (!this.userId)
      {
        this.getId()
      }
    }
  },
  beforeMount() {
    if (typeof localStorage.getItem("token")=='string') {this.getUser()}
    if (!localStorage.getItem("userId") && !localStorage.getItem("token"))
    {
      this.getId();
    }
  },
  methods: {
    winStepper(windowData) {
      this.sideNav = windowData.sideNav;
    },
    getUser() {
      axios({
        method: "GET",
        url: "/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("userData", response.data.data);
            this.$store.commit("changeCart", response.data.totalLength);
            this.$store.commit("changeWishlist", response.data.wishlistcount);
            // this.$store.commit("userType", response.data.data.user.role);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getId() {
      axios({
        method: "GET",
        url: "/get/guestId",
      })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("userId", response.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
};
</script>
