<template>
  <v-layout wrap justify-end>
    <v-flex xs12 sm12 md8 lg8 xl8>
      <v-menu offset-y nudge-bottom="10" nudge-left="20" left>
        <template v-slot:activator="{ attrs, on }">
          <v-btn block text rounded :ripple="false" v-bind="attrs" v-on="on">
            <v-img src="./../../assets/icons/User.png" height="25px" contain />
          </v-btn>
        </template>
        <v-card tile flat :elevation="0" max-width="250px">
          <v-layout wrap justify-center>
            <v-flex pt-4 xs12>
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 16px;
                  color: #000000;
                "
              >
                Welcome
              </span>
            </v-flex>
            <v-flex xs12>
              <span
                v-if="!appLogin"
                style="
                  font-family: poppinsmedium;
                  font-size: 13px;
                  color: #1e1b1b;
                "
              >
                Login your account
              </span>
            </v-flex>
            <v-flex pt-2 xs12>
              <v-icon color="#CCB4B4" size="30">
                mdi-account-circle-outline
              </v-icon>
            </v-flex>
            <v-flex xs12>
              <router-link to="/Profile">
                <span
                  v-if="appLogin"
                  style="
                    font-family: poppinsmedium;
                    font-size: 13px;
                    color: #1e1b1b;
                  "
                >
                  {{ appUser.name }}
                </span>
              </router-link>
            </v-flex>
            <v-flex xs12 py-4>
              <v-btn
                v-if="!appLogin"
                color="#0000001f"
                outlined
                tile
                small
                :to="'/loginForm'"
                :ripple="false"
              >
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 14px;
                    color: #30b868;
                  "
                >
                  LOGIN
                </span>
              </v-btn>
              <v-btn
                v-if="appLogin"
                color="#0000001f"
                outlined
                tile
                small
                :ripple="false"
                @click="appLogout"
              >
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 14px;
                    color: #30b868;
                  "
                >
                  LOGOUT
                </span>
              </v-btn>
            </v-flex>
            <v-flex xs10 py-4 v-if="!appLogin">
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 v-if="!appLogin">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span
                    style="
                      font-family: poppinsregular;
                      font-size: 13px;
                      color: #717171;
                    "
                  >
                    Create an account
                  </span>
                </v-flex>
                <v-flex xs12 py-2 pb-6>
                  <v-btn
                    color="#0000001f"
                    outlined
                    tile
                    small
                    to="/signUpForm"
                    :ripple="false"
                  >
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 14px;
                        color: #30b868;
                      "
                    >
                      SIGNUP
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-menu>
    </v-flex>
  </v-layout>
</template>
  <script>
export default {
  data() {
    return {
      user: {},
    };
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    appUser() {
      return this.$store.state.userData;
    },
  },

  methods: {
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
  },
};
</script>