import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios'
import store from './store'
import VueElementLoading from 'vue-element-loading'
import ServerError from './components/Common/500'
import ImageLoader from './components/Common/imageLoader'
import PageLoader from './components/Common/pageLoader'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueGtag from "vue-gtag";

Vue.component('ServerError', ServerError)
Vue.component('ImageLoader', ImageLoader)
Vue.component('PageLoader', PageLoader)
Vue.component('VueElementLoading', VueElementLoading)
import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)
Vue.use(VueGtag, {
    config: { id: "G-FG6BLD3LBQ" }
});

Vue.config.productionTip = false

window.axios = require('axios')

// Vue.prototype.mediaUURL = "https://mudumalaitigereserve.s3.ap-south-1.amazonaws.com/"
// Vue.prototype.mediaURL = "http://192.168.55.194:4400/wp/";
// axios.defaults.baseURL = "http://192.168.49.195:4400";
// Vue.prototype.ipURL = "http://192.168.49.195:4400"

Vue.prototype.mediaUURL = "https://mudumalaitigereserve.s3.ap-south-1.amazonaws.com/"
    // Vue.prototype.mediaURL = "https://ecoshopapi.mudumalaitigerreserve.com/wp/";
axios.defaults.baseURL = "https://api.mudumalaiecoshop.com";
Vue.prototype.ipURL = "https://api.mudumalaiecoshop.com"


// Vue.prototype.axios = axios
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    mounted() {
        AOS.init()
    },
}).$mount('#app')